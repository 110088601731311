import { gql } from '@apollo/client';
// Fragments
import { UserFields } from './fragments';

export const ME = gql`
  query Me {
    me {
      ...UserFields
    }
  }
  ${UserFields}
`;

export const ME_PROFILE_SETUP = gql`
  query MeProfileSetup {
    me {
      id
      role
      lastName
      firstName
      slug
      email
      phoneNumber
      isOnboarded
      isEmailSet
      onboardingSteps
      status
      hasPassword
      phoneOptIn
      brandDealSettings {
        id
        contactEmail
        contactPhone
        minFlatPrice
        industryCategories {
          isEnabled
          label
          value
        }
        isAcceptingFreeProducts
        isArticlesPrEnabled
        minBookMePrice
        minSocialPrice
        minSponsoredLivePrice
      }
      sports {
        id
        name
      }
      hashtags {
        id
        name
      }
      storeDetails {
        id
        athleticLevel
        storeName
        storeTitle
        bio
        hometown
        avatarURL
        socialMediaLink
        description
        coverURL
        facebookLink
        twitterLink
        instagramLink
        tiktokLink
        youtubeLink
        birthDate
        gender
        instagramFollowing
        facebookFollowing
        twitterFollowing
        tiktokFollowing
        youtubeFollowing
        careerStatus
        hometown
        city
        country
        state
        favoriteNumber
        team {
          id
          name
          sport
        }
        league {
          id
          name
          sport
        }
        college {
          id
          name
        }
      }
      sports {
        id
        name
      }
    }
  }
`;
