import { gql } from '@apollo/client';

export const EDIT_STORE = gql`
  mutation EditStore($input: StoreEditInput!) {
    editStore(input: $input) {
      store {
        id
        isOnboarded
        storeDetails {
          id
          storeName
          avatarURL
          bio
          description
          signUpFlow
        }
      }
    }
  }
`;

export const UPLOAD_STORE_IMAGE = gql`
  mutation UploadStoreImage($input: StoreImageInput!) {
    uploadStoreImage(input: $input) {
      id
      avatarURL
    }
  }
`;

export const UPLOAD_STORE_COVER = gql`
  mutation UploadStoreCover($input: StoreImageInput!) {
    uploadStoreCover(input: $input) {
      id
    }
  }
`;

export const CREATE_VERIFICATION_VIDEO = gql`
  mutation CreateVerificationVideo($input: CreateVerificationVideoInput!) {
    createVerificationVideo(input: $input) {
      id
    }
  }
`;

export const CREATE_STREAM_TIP = gql`
  mutation CreateStreamTip($input: StreamTipCreateInput!) {
    createStreamTip(input: $input) {
      stripeIntentClientSecret
      message
      amount
    }
  }
`;

export const TRACK_SHARE = gql`
  mutation TrackShare($input: TrackShareInput!) {
    trackShare(input: $input)
  }
`;
