// Types
import { Me } from 'api/auth/types/Me';
import { UserRole, StoreStatus } from 'api/graphql-global-types';

type User = Me | undefined;

export const getUserFullName = (user: User): string => {
  return `${user?.me?.firstName || ''} ${user?.me?.lastName || ''}`.trim();
};

export const isUserAthlete = (user: User): boolean => {
  return user?.me.role === UserRole.Athlete;
};

export const isUserOrganization = (user: User): boolean => {
  return user?.me.role === UserRole.Organization;
};

export const isUserContentCreator = (user: User): boolean => {
  return user?.me.role === UserRole.ContentCreator;
};

export const isActiveStore = (user: User): boolean => {
  return user?.me.status === StoreStatus.Active;
};

export const isOnboardedStore = (user: User): boolean => {
  return !!user?.me.isOnboarded;
};

export const isUserCustomer = (user: User): boolean => {
  return user?.me.role === UserRole.Customer;
};

export const isStoreRole = (userRole?: UserRole | null): boolean => {
  if (!userRole) return false;

  return [
    UserRole.Athlete,
    UserRole.Organization,
    UserRole.ContentCreator,
  ].includes(userRole);
};

export const isAdminRole = (userRole?: UserRole | null): boolean => {
  if (!userRole) return false;

  return [UserRole.Admin].includes(userRole);
};

export const mapStreamerRoleToLabel = (role: UserRole): string => {
  switch (role) {
    case UserRole.Athlete:
      return 'Athlete';
    case UserRole.Organization:
      return 'Organization';
    case UserRole.ContentCreator:
      return 'Content Creator';
    default:
      return '';
  }
};
